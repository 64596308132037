import React from "react";
import "../styles/components/Generated.scss";

const Generated = ({
  name,
  occupation,
  phone,
  email,
  bannerImg,
  bannerURL,
}: GeneratedProps) => {
  return (
    <div style={{display: 'table'}}>
      <img
        src="https://signatures.yolkone.com/img/yolk_logo.gif"
        alt="Yolk Studio logo"
        width={"218px"}
        height={"75px"}
      />
      <br />
      <img
        src="https://signatures.yolkone.com/img/line.png"
        alt="Horizontal line"
        width={47}
        height={1}
      />
      <p
        style={{
          fontFamily: "Helvetica, Arial, sans-serif",
          color: "#201d1d",
          fontSize: 11,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        {`${name} - ${occupation}`}
      </p>
      <p
        style={{
          fontFamily: "Helvetica, Arial, sans-serif",
          color: "#9bb0b8",
          fontSize: 11,
          margin: 0,
          marginBottom: 5,
        }}
      >
        <a
          href={`tel:${phone.split(" ").join("")}`}
          style={{
            textDecoration: "none",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#9bb0b8",
            fontSize: 11,
          }}
        >
          {phone}
        </a>{" "}
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <a
          href={`mailto:${email}`}
          style={{
            textDecoration: "none",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#9bb0b8",
            fontSize: 11,
          }}
        >
          {email}
        </a>
      </p>
      <p
        style={{
          fontFamily: "Helvetica, Arial, sans-serif",
          color: "#9bb0b8",
          fontSize: 11,
          margin: 0,
        }}
      >
        <a
          href="https://www.yolkstudio.com"
          style={{
            textDecoration: "none",
            fontFamily: "Helvetica, Arial, sans-serif",
            color: "#9bb0b8",
            fontSize: 11,
          }}
        >
          www.yolkstudio.com
        </a>
      </p>
    </div>
  );
};

export default Generated;

type GeneratedProps = {
  name: string;
  occupation: string;
  phone: string;
  email: string;
  bannerImg?: string;
  bannerURL?: string;
};
